<template>
  <div>
    <date-tabs />
    <b-card>
      <b-row class="mb-1">
        <b-col lg="3">
          <label>Person ID</label>
          <b-input-group>
            <b-form-input
              v-model="personID_"
              placeholder="Номер документа"
              @keyup.enter="
                (val) => {
                  personID = val.target.value;
                  tableParams.page = 1
                }
              "
            />
            <b-input-group-append
              v-if="personID_"
              is-text
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                @click="
                  () => {
                    personID_ = '';
                    personID = '';
                  }
                "
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="3">
          <label>Наличие соответствий</label>
          <b-input-group style="flex-wrap: nowrap">
            <v-select
              v-model="isMatch"
              placeholder="Выберите статус"
              :searchable="false"
              :options="[
                { label: '-', value: undefined },
                { label: 'Да', value: true },
                { label: 'Нет', value: false },
              ]"
              class="w-100"
              :clearable="false"
            />
            <b-input-group-append
              v-if="isMatch"
              is-text
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                @click="
                  (val) => {
                    isMatch = undefined;
                  }
                "
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <editable-table
        ref="table"
        :api="Oneton"
        :fields="fields"
        :params="tableParams"
        :on-click="onClick"
      >
        <template #cell(created)="data">
          <span>
            {{ new Date(data.item.created).toLocaleDateString() }}
          </span>
          <span>
            {{ (data.item.exec_time_ms / 1000).toFixed(2) + "с" }}
          </span>
        </template>
        <!-- <template #cell(duration)="data">
          <span>
            {{ new Date(data.item.created).toLocaleTimeString() }}
          </span>
        </template> -->
        <template #cell(threshold)="data">
          <span> {{ data.item.threshold }}% </span>
        </template>
        <template #cell(count)="data">
          <span>
            {{
              data.item.response &&
                data.item.response.data &&
                data.item.response.data.results &&
                data.item.response.data.results.length
                ? data.item.response.data.results.length
                : "-"
            }}
          </span>
        </template>

        <template #cell(position)="data">
          <span>
            {{
              data.item.response &&
                data.item.response.data &&
                data.item.response.data.results &&
                data.item.response.data.results.length &&
                data.item.response.data.results.find((r) => r.personId == personID)
                ? data.item.response.data.results.indexOf(
                  data.item.response.data.results.find((r) => r.personId == personID)
                ) + 1
                : "-"
            }}
          </span>
        </template>

        <template #cell(probability)="data">
          <span>
            {{
              data.item.response &&
                data.item.response.data &&
                data.item.response.data.results &&
                data.item.response.data.results.length &&
                data.item.response.data.results.find((r) => r.personId == personID)
                ? data.item.response.data.results.find((r) => r.personId == personID)
                  .recognitionValue
                : "-"
            }}
          </span>
        </template>

        <template
          #cell(photo)="data"
          class="px-1"
        >
          <b-media vertical-align="center">
            <template>
              <b-link
                v-if="data.item.photo"
                :href="
                  (data.item.photo.minio_path || '').replace(
                    'http://minio:9000',
                    VUE_APP_MINIO || ''
                  )
                "
                target="_blank"
              >
                <b-avatar
                  size="32"
                  :src="
                    (
                      data.item.photo.cropped_minio_path ||
                      data.item.photo.minio_path ||
                      ''
                    ).replace('http://minio:9000', VUE_APP_MINIO || '')
                  "
                  :variant="`light-primary`"
                />
              </b-link>
            </template>
          </b-media>
        </template>
      </editable-table>
    </b-card>
    <b-modal
      id="modal-lg"
      ref="modal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      title="Информация"
    >
      <h3>Фотография с камеры</h3>
      <div class="employee-task d-flex justify-content-between align-items-center mb-2">
        <b-media
          v-if="requestData"
          no-body
        >
          <b-media-aside class="mr-75">
            <b-avatar
              rounded
              size="100"
              :src="
                (requestData.photo.minio_path || '').replace(
                  'http://minio:9000',
                  VUE_APP_MINIO || ''
                )
              "
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">
              <b-badge>
                {{ requestData.id }}
              </b-badge>
            </h6>
            <div>{{ new Date(requestData.created).toLocaleString() }}</div>
            <div>
              <!-- Math.max(
                      ...requestData.response.data.results.map((r) => r.recognitionValue)
                    )  -->
              {{
                requestData.response &&
                  requestData.response.data &&
                  requestData.response.data.results &&
                  requestData.response.data.results.length
                  ? requestData.threshold + "%"
                  : "-"
              }}
            </div>
          </b-media-body>
        </b-media>
      </div>
      <h3>Результаты из базы фотографий 1/n</h3>
      <div
        v-for="row in rowData"
        :key="row.personId"
        class="employee-task d-flex justify-content-between align-items-center mb-1"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-link
              :href="
                (row.minio_path || '').replace('http://minio:9000', VUE_APP_MINIO || '')
              "
              target="_blank"
            >
              <b-avatar
                rounded
                size="64"
                :src="
                  (row.cropped_minio_path || '').replace(
                    'http://minio:9000',
                    VUE_APP_MINIO || ''
                  )
                "
              />
            </b-link>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">
              {{ row.personId }}
            </h6>
            <span>{{ row.recognitionValue }}%</span>
          </b-media-body>
        </b-media>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BTabs,
  BTab,
  BMedia,
  BAvatar,
  BLink,
  BModal,
  BMediaAside,
  BMediaBody,
  BBadge,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from 'bootstrap-vue'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import QueriesTable from './queries/QueriesTable.vue'
import DateTabs from './statistics/DateTabs.vue'
import EditableTable from '@/components/EditableTable.vue'
import { Oneton } from '@/api/schema'

const fields = ref([
  {
    visible: true,
    label: 'ID',
    key: 'id',
  },
  {
    visible: true,
    label: 'Дата',
    key: 'created',
  },
  {
    visible: false,
    label: 'Время',
    key: 'duration',
  },
  {
    visible: true,
    label: 'Фото',
    key: 'photo',
  },
  {
    visible: true,
    label: 'Порог',
    key: 'threshold',
  },
  {
    visible: false,
    label: 'Позиция',
    key: 'position',
  },
  {
    visible: false,
    label: 'Вероятность',
    key: 'probability',
  },
  {
    visible: true,
    label: 'Количество',
    key: 'count',
  },
])

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCardText,
    QueriesTable,
    DateTabs,
    EditableTable,
    BMedia,
    BAvatar,
    BLink,
    BModal,
    BMediaAside,
    BMediaBody,
    BBadge,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    vSelect,
  },
  setup(props, ctx) {
    function onClick(item) {
      if (!item?.response?.data?.results.length) return
      rowData.value = item?.response?.data?.results || []
      requestData.value = item || {}
      ctx.refs.modal.show()
    }

    const rowData = ref([])
    const requestData = ref()
    const personID = ref('')
    const personID_ = ref('')
    const isMatch = ref(undefined)
    const tableParams = computed(() => ({
      order: -1,
      start_date: ctx.parent.$store.state.appstats.start_date,
      end_date: ctx.parent.$store.state.appstats.end_date,
      person_id: personID.value,
      is_match: isMatch?.value?.value,
    }))

    watch([tableParams], () => {
      setTimeout(() => {
        ctx.refs.table.fetchList()
      }, 1)
    })

    watch([personID], () => {
      if (personID.value) {
        fields.value[5].visible = true
        fields.value[6].visible = true
      } else {
        fields.value[5].visible = false
        fields.value[6].visible = false
      }
    })

    return {
      Oneton,
      fields,
      onClick,
      VUE_APP_MINIO: process.env.VUE_APP_MINIO,
      rowData,
      requestData,
      personID,
      personID_,
      isMatch,
      tableParams,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
